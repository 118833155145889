import React from 'react';
import { Panel, Stack, Box, Text } from 'chaser-components';
import useCreditor from '../../../../hooks/useCreditor';
import useDebtor from '../../../../hooks/useDebtor';

const Note = () => {
  const { name } = useCreditor();
  const { note } = useDebtor();

  return (
    <>
      {note ? (
        <Panel intent="warning">
          <Box p="medium">
            <Stack>
              <Text fontSize="medium" fontWeight="bold">
                Note from {name}
              </Text>
              <Text>{note}</Text>
            </Stack>
          </Box>
        </Panel>
      ) : null}
    </>
  );
};

export default Note;
