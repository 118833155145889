import React, { useContext } from 'react';
import { Card, Box, Heading, Text, Stack, Button } from 'chaser-components';
import { PaymentContext } from '../../../../../../../providers/paymentContext';
import Alert from './alert.svg';

const ErrorMessage = () => {
  const {
    setPaymentResult,
    setShowModal,
    paymentOptions: { paymentResult },
  } = useContext(PaymentContext);

  const { message, submessage, refetch, isLoading = false, reset = true } = paymentResult.error;

  return (
    <Stack gap="medium">
      <Card width={'max'}>
        <Box p="medium">
          <Stack gap="medium" textAlign="center">
            <img src={Alert} alt="" width={'50px'} style={{ alignSelf: 'center' }} />
            <Heading>Payment failed</Heading>
            <Stack gap="xsmall">
              <Text color="Mid Neutral 1">{message}</Text>
              {submessage && <Text color="Mid Neutral 1">{submessage}</Text>}
            </Stack>
            <Stack gap="xsmall">
              <Button
                data-testid="continue-btn"
                loading={isLoading}
                style={{ width: '100%' }}
                onClick={() => {
                  if (refetch) refetch();
                  if (reset) setPaymentResult(undefined);
                }}
              >
                Retry
              </Button>

              <Button
                data-testid="cancel-btn"
                onClick={() => setShowModal(false, { reset: false })}
                textAlign="center"
                appearance="secondary"
              >
                Cancel
              </Button>
            </Stack>
          </Stack>
        </Box>
      </Card>
      <Text textAlign="center" color="Mid Neutral 2">
        {'Powered by Stripe payments'}
      </Text>
    </Stack>
  );
};

export default ErrorMessage;
