class ApiError extends Error {
  constructor(msg, { code, meta, info, action, log = true } = {}) {
    super(msg);
    this.code = code;
    this.meta = meta;
    this.info = info;
    this.action = action;
    this.log = log;
  }
}
export default ApiError;
