import React from 'react';
import { Container, Text, Shelf, Icon } from 'chaser-components';
import style from './NavigationBar.module.scss';
import useCreditor from '../../hooks/useCreditor';
import { useRouteMatch } from 'react-router-dom';
import { matchPath, useLocation } from 'react-router';
import useMedia from '../../hooks/useMedia';

const NavigationBar = () => {
  const { pathname } = useLocation();
  const { params } =
    matchPath(pathname, {
      path: ['/c/:orgId', '/creditor/:orgId'],
    }) || {};

  const match = useRouteMatch({
    path: ['/c/:orgId/d/:contactId', '/creditor/:orgId/debtor/:contactId'],
    strict: true,
  });

  const isMobile = useMedia('(max-width: 1000px)');
  const { name, contactDetails } = useCreditor(params?.orgId);
  const { email } = contactDetails || {};

  return match ? (
    <nav className={style.navbar}>
      <Container>
        <Shelf alignItems="center" justifyContent="space-between">
          <Text color="white" fontWeight="bold">
            {name}{' '}
            <Text fontWeight="normal" color="white">
              statement
            </Text>
          </Text>
          {!isMobile && (
            <Shelf>
              <a href={`mailto:${email}`}>
                <Icon color="white" name="email" />
              </a>
            </Shelf>
          )}
        </Shelf>
      </Container>
    </nav>
  ) : null;
};
export default NavigationBar;
