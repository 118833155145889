import cx from 'classnames';
import { string, bool, oneOf, number, oneOfType } from 'prop-types';
import React from 'react';
import { Loader, Box } from 'chaser-components';
import style from './LoadingMessage.module.scss';

const LoadingMessage = ({
  message,
  textAlign,
  justifyContent,
  verticalAlign,
  margins,
  size,
  center,
  p,
  spinner,
  elipses,
  overlay,
  className,
}) => (
  <Box p={p}>
    <div
      className={cx(
        !center ? style.loadingMessageContainer : style.loadingMessageContainer__center,
        style.margins[margins],
        elipses && style.ellipsesLoader,
        overlay && style.overlay,
        className,
      )}
      style={{
        textAlign,
        justifyContent,
        verticalAlign,
      }}
      data-testid="spinner"
    >
      {message}
      {spinner && <Loader size={size} />}
    </div>
  </Box>
);

export default LoadingMessage;

LoadingMessage.propTypes = {
  elipses: bool,
  spinner: bool,
  overlay: bool,
  p: oneOfType([string, number]),
  textAlign: string,
  size: number,
  verticalAlign: string,
  message: string,
  center: bool,
  className: string,
  justifyContent: string,
  margins: oneOf(['small', 'none']),
};

LoadingMessage.defaultProps = {
  elipses: false,
  overlay: false,
  spinner: true,
  p: 'small',
  textAlign: 'center',
  verticalAlign: 'inherit',
  justifyContent: 'center',
  margins: 'small',
  size: 30,
  message: '',
  center: false,
  className: undefined,
};
