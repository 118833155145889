import { useQuery } from 'react-query';
import ApiService from '../services/api';
import useContact from './useContact';

const getDebtor = async (key, orgId, contactId) => {
  const { data } = await ApiService().get(`/creditor/${orgId}/debtor/${contactId}`);
  return data;
};

function useDebtor() {
  const { orgId, contactId } = useContact();
  const { data } = useQuery(['debtor', orgId, contactId], getDebtor, {
    enabled: contactId,
  });
  const debtor = data?.debtor || {};

  return {
    defaultCurrency: debtor.DefaultCurrency,
    note: debtor.customerPortalDetails?.notes,
    name: debtor.Name,
    totalCredit: debtor.totalCredit,
    totalOutstanding: debtor.Balances?.AccountsReceivable.Outstanding || 0,
    totalOverdue: debtor.Balances?.AccountsReceivable.Overdue || 0,
    directDebit: debtor.directDebit,
    debtorId: contactId,
  };
}

export default useDebtor;
