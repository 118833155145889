import * as Sentry from '@sentry/browser';
import { BrowserTracing } from '@sentry/tracing';

const { REACT_APP_SENTRY_DSN, REACT_APP_ENV } = process.env;

const initSentry = () => {
  if (REACT_APP_SENTRY_DSN) {
    Sentry.init({
      dsn: REACT_APP_SENTRY_DSN,
      environment: REACT_APP_ENV,
      integrations: [new BrowserTracing()],
      beforeSend(evt, hint) {
        evt.extra = { ...evt.extra, ...hint.originalException?.info };
        return evt;
      },
      ignoreErrors: ['Network Error', /ResizeObserver/, 'Request aborted'],
    });
  }
};

function logError(message, info = {}, severity = Sentry.Severity.Fatal) {
  // Throw and catch error to get a stack trace
  try {
    throw new Error(message);
  } catch (e) {
    console.error(e);
    Sentry.withScope(scope => {
      scope.setLevel(severity);
      scope.setExtras(info);
      Sentry.captureException(e);
    });
  }
}

export { initSentry, logError };
