import ApiService from './api';

async function getPaymentIntent({
  currency,
  orgId,
  contactId,
  invoices,
  paymentType = 'card',
  provider = 'STRIPE',
  successfulPaymentRedirect = '',
}) {
  return await ApiService().post(`/creditor/${orgId}/debtor/${contactId}/payments`, {
    provider,
    currency,
    invoices,
    paymentType,
    successfulPaymentRedirect,
  });
}

export default getPaymentIntent;
