import ApiService from '../../../../../../../services/api';

async function confirmContactDebitAgreement(debtorId, currency) {
  const { data } = await ApiService().patch(`/debtor/${debtorId}/confirm-debit-agreement`, {
    currency,
  });
  return data;
}

export default confirmContactDebitAgreement;
