const colors = {
  'Dark Neutral 1': '#3c4757',
  'Mid Neutral 1': '#6e767f',
  'Mid Neutral 2': '#9ba1ae',
  'Mid Neutral 3': '#d7dbe2',
  'Light Neutral 1': '#e3e5ea',
  'Light Neutral 2': '#f4f5f7',
  'Light Neutral 3': '#f9f9fa',
  Red: '#eb5757',
  Black: '#000000',
  Blue: '#1b82be',
  'Mid Blue': '#529eca',
  'Neutral Blue': '#597089',
  'Dark Yellow': '#e58d27',
  Green: '#2ea970',
  'Dark Green': '#129558',
  White: '#ffffff',
  Yellow: '#FAB666',
  Orange: ' #ff780d',
};

module.exports = colors;
