/* eslint-disable react/prop-types */
import React from 'react';
import { Formik, Form } from 'formik';
import {
  useStripe,
  useElements,
  PaymentElement,
  ExpressCheckoutElement,
} from '@stripe/react-stripe-js';
import { Stack, Button } from 'chaser-components';
import LoadingMessage from '../LoadingMessage/index';
import useSelectedTotal from '../../hooks/useSelectedTotal';
import useTotalWithOrWithoutFees from '../../hooks/useTotalWithOrWithoutFees';
import useCreditor from '../../hooks/useCreditor';
import { toCurrency } from '../../util';
import { paymentTypes } from '../../util/constants';

const CheckoutForm = ({ onCancel, isLoading, setPaymentError, isDebit, clientSecret }) => {
  const stripe = useStripe();
  const elements = useElements();
  const { amount, currency } = useSelectedTotal(true);
  const { baseCurrency } = useCreditor();
  const onClick = ({ resolve }) => {
    const options = {
      emailRequired: true,
    };
    resolve(options);
  };
  const onConfirm = async () => {
    const { error } = await stripe.confirmPayment({
      elements,
      clientSecret,
      confirmParams: {
        return_url: window.location.href,
      },
    });

    if (error) {
      setPaymentError({
        message:
          error?.message || 'There was an issue processing your payment. Please contact support',
      });
    }
  };

  const finalTotal = useTotalWithOrWithoutFees(
    isDebit ? paymentTypes.bacs_debit : paymentTypes.card,
    amount,
    baseCurrency,
    currency,
  );

  if (isLoading) return <LoadingMessage />;

  return (
    <Formik
      initialValues={{}}
      validateOnBlur={false}
      onSubmit={async (_, { setSubmitting }) => {
        if (!stripe || !elements) return;

        let result;
        try {
          result = await stripe.confirmPayment({
            elements,
            confirmParams: {
              return_url: window.location.href,
            },
          });
        } catch (e) {
          throw e;
        }
        const codes = ['validation_error'];
        if (codes.includes(result?.error?.type)) return;
        setSubmitting(false);

        if (result?.error) {
          setPaymentError({
            ...result.error,
            submessage: 'Please check your details and try again.',
          });
        }
      }}
    >
      {({ isSubmitting }) => (
        <Form>
          <Stack gap="small">
            <ExpressCheckoutElement onClick={onClick} onConfirm={onConfirm} />
            <PaymentElement id="payment-element" />
            <Button
              data-testid="pay-btn"
              loading={isSubmitting}
              style={{ width: '100%' }}
              disabled={false}
              type="submit"
            >
              {!isDebit ? `Pay ${toCurrency(finalTotal, currency)}` : 'Continue'}
            </Button>
            <Button onClick={onCancel} textAlign="center" appearance="secondary">
              Cancel
            </Button>
          </Stack>
        </Form>
      )}
    </Formik>
  );
};

export default CheckoutForm;
