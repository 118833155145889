import { useQuery } from 'react-query';
import ApiService from '../services/api';
import useContact from './useContact';

const getPaidInvoices = async (key, orgId, contactId) => {
  const { data } = await ApiService().get(`/creditor/${orgId}/debtor/${contactId}/invoices?paid=1`);
  return data;
};

function usePaidInvoices() {
  const { orgId, contactId } = useContact();

  const { data, isLoading } = useQuery(['paidInvoices', orgId, contactId], getPaidInvoices, {
    enabled: contactId,
    suspense: false,
  });

  return {
    invoices: data,
    isLoading,
  };
}

export default usePaidInvoices;
