import React from 'react';
import { render } from 'react-dom';
import './index.scss';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { Router } from 'react-router';
import { createTheme, ThemeProvider } from 'chaser-components';
import ToastContainer from './components/ToastContainer/index';
import { QueryCache, ReactQueryCacheProvider } from 'react-query';
import LoadBoundary from './components/LoadBoundary/index';
import { initSentry } from './util/analytics';
import history from './util/history';
import 'chaser-components/dist/index.css';

const queryCache = new QueryCache({
  defaultConfig: {
    queries: {
      suspense: true,
    },
  },
});

const theme = createTheme();
initSentry();

const target = document.querySelector('#root');

render(
  <React.StrictMode>
    <ReactQueryCacheProvider queryCache={queryCache}>
      <Router history={history}>
        <ThemeProvider theme={theme}>
          <LoadBoundary center>
            <ToastContainer />
            <App />
          </LoadBoundary>
        </ThemeProvider>
      </Router>
    </ReactQueryCacheProvider>
  </React.StrictMode>,
  target,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
