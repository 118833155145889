import React, { useMemo } from 'react';
import { Text, ButtonGroup } from 'chaser-components';
import flatten from 'lodash/flatten';
import { Table, Cell } from '../../../../components/InvoiceTable/index.js';
import usePaidInvoices from '../../../../hooks/usePaidInvoices';
import { toCurrency } from '../../../../util';
import { formatDate } from '../../../../util/time';
import DownloadButton from '../../../../components/DownloadButton/index';
import useMedia from '../../../../hooks/useMedia';
import { pullAt } from 'lodash';
import style from './Paid.module.scss';
import useCreditor from '../../../../hooks/useCreditor.js';

const Paid = () => {
  const { accountingSoftware } = useCreditor();
  const { invoices, isLoading } = usePaidInvoices();
  const isMobile = useMedia('(max-width: 1000px)');

  const data = useMemo(
    () =>
      flatten(
        invoices ??
          []
            .filter(i => !!i.FullyPaidOnDate || !!i.paymentPlan)
            .map((invoice, index) => {
              if (invoice.paymentPlan) {
                return invoice.paymentPlan.instalments
                  .filter(inst => inst.amountDue === 0)
                  .map((instalment, i) => ({
                    ...invoice,
                    instalment,
                    number: i + 1,
                    total: invoice.paymentPlan.instalments.length,
                    FullyPaidOnDate: instalment.fullyPaidOnDate,
                    key: `${index}-${i}`,
                  }));
              }
              return {
                ...invoice,
                key: index,
              };
            }),
      ).sort((a, b) => new Date(b.FullyPaidOnDate) - new Date(a.FullyPaidOnDate)),
    [invoices],
  );

  const thTextInMobile = {
    textTransform: !isMobile ? '' : 'uppercase',
    fontSize: !isMobile ? '' : '11px',
  };

  const columns = [
    {
      accessor: 'InvoiceNumber',
      headerStyle: { width: !isMobile ? '16%' : '25%', ...thTextInMobile },
      header: 'No.',
      cell: (number, invoice) => (
        <Cell>
          <Text>
            {!isMobile ? number : number.split('-')[1]} {invoice.instalment && '(instalment)'}
          </Text>
        </Cell>
      ),
    },
    {
      accessor: 'DueDate',
      headerStyle: { width: '15%' },
      header: 'Due Date',
      cell: DueDate => (
        <Cell>
          <Text>{formatDate(DueDate, { month: 'short' })}</Text>
        </Cell>
      ),
    },
    {
      accessor: 'FullyPaidOnDate',
      headerStyle: { width: !isMobile ? '20%' : '50%', ...thTextInMobile },
      header: 'Status',
      cell: fullyPaidOnDate => (
        <Cell>
          <Text color="Green">
            Paid on{' '}
            {fullyPaidOnDate ? formatDate(fullyPaidOnDate, { month: 'short' }) : <em>unknown</em>}
          </Text>
        </Cell>
      ),
    },
    {
      accessor: 'Total',
      header: 'Outstanding',
      align: 'right',
      headerStyle: { width: '20%', textAlign: 'right', ...thTextInMobile },
      headerAlign: 'right',
      cell: (total, invoice) => (
        <Cell>
          <Text fontSize="medium">{toCurrency(total, invoice.CurrencyCode)}</Text>
        </Cell>
      ),
    },
    {
      accessor: '_',
      align: 'right',
      headerStyle: { textAlign: 'right' },
      headerAlign: 'right',
      cell: (_, invoice) => {
        return (
          <Cell>
            <ButtonGroup justifyContent="flex-end">
              <DownloadButton.InvoicePDF
                accountingSoftware={accountingSoftware}
                invoicePdfLink={invoice.invoicePdfLink}
                invoiceId={invoice._id}
                invoiceNumber={invoice.InvoiceNumber}
              />
            </ButtonGroup>
          </Cell>
        );
      },
    },
  ];

  const availableColumns = isMobile ? pullAt(columns, [0, 2, 3, 4]) : columns;
  return (
    <Table
      loading={isLoading}
      data={data}
      rowClassName={style.row}
      columns={availableColumns}
      noDataIndication="No invoices"
    />
  );
};

export default Paid;
