import React, { useContext } from 'react';
import { Card, Shelf, Box, Stack, Modal, Heading, Text, Token } from 'chaser-components';
import ByCard from './components/ByCard';
import ByDebit from './components/ByDebit';
import { bool, oneOfType, string, func } from 'prop-types';
import { PaymentContext } from '../../../../../providers/paymentContext';
import ErrorMessage from './components/ErrorMessage/index';
import SuccessMessage from './components/SuccessMessage/index';
import pluralize from 'pluralize';
import useSelectedTotal from '../../../../../hooks/useSelectedTotal';
import useProvider from '../../../../../hooks/useProvider';
import useCreditor from '../../../../../hooks/useCreditor';
import useTotalWithOrWithoutFees from '../../../../../hooks/useTotalWithOrWithoutFees';
import { toCurrency, stateFeesText } from '../../../../../util';
import {
  payment,
  paymentProviders,
  paymentTypes,
  ddMandateMapping,
} from '../../../../../util/constants';

import style from './PaymentModal.module.scss';

const StripePaymentOption = ({ paymentType }) => {
  if (paymentType === payment.card) return <ByCard />;
  if (paymentType === payment.debit) return <ByDebit />;

  throw new Error('Payment type is not supported');
};

StripePaymentOption.propTypes = {
  paymentType: string,
};

const PaymentOptions = () => {
  const { paymentOptions } = useContext(PaymentContext);
  const { paymentType } = paymentOptions;
  const { numberOfInvoices, amount, currency } = useSelectedTotal(true);
  const { name, baseCurrency } = useCreditor();

  const paymentData = useProvider(paymentProviders.stripe);
  const paymentT = paymentType === paymentTypes.card ? paymentTypes.card : paymentTypes.bacs_debit;
  const feesText = stateFeesText(paymentData, paymentT, currency);
  const totalWithFeesForCards = useTotalWithOrWithoutFees(
    paymentType === payment.debit ? paymentTypes.bacs_debit : paymentTypes.card,
    amount,
    baseCurrency,
    currency,
  );

  return (
    <Stack gap="large">
      <Card width={'max'}>
        <Box p="medium">
          <Stack dividers gap="medium">
            <Stack>
              <Shelf justifyContent="space-between">
                <Heading textAlign="left" fontWeight="400" fontSize="heading.2">
                  {paymentType === payment.debit
                    ? `${ddMandateMapping[currency.toLowerCase()].name} ${
                        currency.toLowerCase() === 'usd' ? '' : 'Direct'
                      } Debit`
                    : ''}
                  {paymentType === payment.card ? 'Credit/debit Card' : ''}
                </Heading>
                {paymentType !== payment.debit && (
                  <Token color="Mid Neutral 1" backgroundColor="Light Neutral 1">
                    {`${pluralize('invoices', numberOfInvoices, true)}`}
                  </Token>
                )}
              </Shelf>
              <Text color="Mid Neutral 1" fontSize="small" textAlign="left">
                <>
                  <Text fontWeight="bold">{`${toCurrency(totalWithFeesForCards, currency)}`}</Text>{' '}
                  <Text>{` to `}</Text>
                </>
                {name}
              </Text>
              {feesText && (
                <Text color="Mid Neutral 1" fontSize="small" textAlign="left">
                  <Text>{feesText}</Text>
                </Text>
              )}
              {paymentType === payment.debit && (
                <Text color="Mid Neutral 1" fontSize="small" textAlign="left">
                  <Text>Automatic payments for all new invoices</Text>
                </Text>
              )}
            </Stack>
            <StripePaymentOption paymentType={paymentType} />
          </Stack>
        </Box>
      </Card>
      <Text textAlign="center" color="Mid Neutral 2">
        {paymentType !== payment.bank && 'Powered by Stripe payments'}
      </Text>
    </Stack>
  );
};

const StripeModal = ({ open, toggle }) => {
  const { paymentOptions } = useContext(PaymentContext);
  const { paymentResult } = paymentOptions;

  return (
    <Modal size="full" open={open} toggle={toggle} disableClose className={style.background}>
      <div className={style.body}>
        <Modal.Body>
          <PaymentSteps error={!!paymentResult?.error} success={paymentResult?.success} />
        </Modal.Body>
      </div>
    </Modal>
  );
};

function PaymentSteps({ error, success }) {
  if (error) return <ErrorMessage />;
  if (success) return <SuccessMessage />;
  return <PaymentOptions />;
}

PaymentSteps.propTypes = {
  error: oneOfType([bool, string]),
  success: bool,
};

export default StripeModal;

StripeModal.propTypes = {
  open: bool,
  toggle: func,
};
