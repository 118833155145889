/* eslint-disable react/prop-types */
import React, { useContext } from 'react';
import { Modal, Stack, Text, Button, ButtonGroup } from 'chaser-components';
import { PaymentContext } from '../../../../providers/paymentContext';
import { ddMandateMapping, payment, paymentProviders } from '../../../../util/constants';
import useCreditor from '../../../../hooks/useCreditor';
import useSelectedTotal from '../../../../hooks/useSelectedTotal';

const DirectDebitOverviewModal = () => {
  const { pay, paymentOptions, setShowModalDirectDebitOverview } = useContext(PaymentContext);
  const { showModalDirectDebitOverview } = paymentOptions;
  const { name } = useCreditor();
  const { currency } = useSelectedTotal();

  return (
    <Modal
      title="Set up automatic payments"
      size="medium"
      open={showModalDirectDebitOverview}
      onClosed={() => {
        setShowModalDirectDebitOverview(false);
      }}
      titleDivider={true}
      toggle={() => {
        setShowModalDirectDebitOverview(false);
      }}
    >
      <Modal.Body>
        <Stack gap="large">
          <Text>
            Paying by
            {` ${ddMandateMapping[currency?.toLowerCase()]?.name} ${
              currency?.toLowerCase() === 'usd' ? '' : 'Direct'
            } Debit`}{' '}
            means you will <Text fontWeight="bold">pay the selected invoices now</Text>, as well as
            instructing your bank to{' '}
            <Text fontWeight="bold">automatically pay for any future invoices</Text> raised by{' '}
            {name}.
          </Text>
          <Text>
            These debits are taken shortly after their due date, with confirmation emails from the
            payment provider (Stripe) being sent to you before and after the payment.
          </Text>
          <Text>
            This instruction can be canceled at any point by contacting your bank directly.
          </Text>
        </Stack>
      </Modal.Body>
      <Modal.Footer>
        <ButtonGroup>
          <Button onClick={() => setShowModalDirectDebitOverview(false)} appearance="secondary">
            Cancel
          </Button>
          <Button
            onClick={async () => {
              setShowModalDirectDebitOverview(false);
              pay({
                provider: paymentProviders.stripe,
                paymentType: payment.debit,
              });
            }}
          >
            Continue
          </Button>
        </ButtonGroup>
      </Modal.Footer>
    </Modal>
  );
};

export default DirectDebitOverviewModal;
