import React from 'react';
import logo from '../../assets/advert_logo.png';
import { Button, ButtonGroup, Text, Stack, Shelf, Panel, Box } from 'chaser-components';
import PropTypes from 'prop-types';

const Advert = ({ hideAdvert }) => {
  if (hideAdvert) {
    return null;
  }

  return (
    <Panel>
      <Box p="medium" backgroundColor="#E8F2FF">
        <Shelf justifyContent="space-between" alignItems="top">
          <Stack>
            <Stack>
              <img width="54px" src={logo} alt="" />
            </Stack>
            <Stack justifyContent="space-between">
              <Stack>
                <Text fontWeight="400" fontSize="22px">
                  Automate your collections with <strong>Chaser</strong>
                </Text>
                <Text color="#6E767F" fontSize="14px">
                  Put an end to late payments with automated payment reminders.
                </Text>
              </Stack>
              <ButtonGroup>
                <Button
                  size="narrow"
                  href="https://www.chaserhq.com/?utm_campaign=PM-2024-Payment%20portal%20ad%20in-app&utm_source=PM-2024-Payment%20portal%20ad%20in-app-home-page&utm_medium=PM-2024-Payment%20portal%20ad%20in-app-home-page&utm_term=PM-2024-Payment%20portal%20ad%20in-app-home-page&utm_content=PM-2024-Payment%20portal%20ad%20in-app-home-page"
                  as="a"
                  target="_blank"
                >
                  Learn more
                </Button>
              </ButtonGroup>
            </Stack>
          </Stack>
        </Shelf>
      </Box>
    </Panel>
  );
};

Advert.propTypes = {
  hideAdvert: PropTypes.bool.isRequired,
};

export default Advert;
