import React from 'react';
import { Panel, Shelf, Text, Box, Stack, TextLink } from 'chaser-components';
import useCreditor from '../../../../hooks/useCreditor';
import style from './Contacts.module.scss';

const Contacts = () => {
  const { logo, contactDetails } = useCreditor();
  const { email } = contactDetails || {};

  return (
    <Panel appearance="secondary">
      <Box p="medium">
        <Shelf justifyContent="space-between" alignItems="top">
          <Stack>
            <Text fontSize="medium" fontWeight="bold">
              Contacts
            </Text>
            <Stack gap="xsmall">
              <Text fontSize="small" color="Mid Neutral 1">
                Email
              </Text>
              <div>
                <TextLink as="a" href={`mailto:${email}`} fontSize="small">
                  {email}
                </TextLink>
              </div>
            </Stack>
          </Stack>
          {logo ? (
            <div className={style.logo}>
              <img src={logo} alt="" />
            </div>
          ) : null}
        </Shelf>
      </Box>
    </Panel>
  );
};

export default Contacts;
