import React from 'react';
import { bool, func } from 'prop-types';
import { Card, Shelf, Box, Stack, Modal, Heading, Text } from 'chaser-components';
import Details from './Details/index.jsx';
import useCreditor from '../../../../../hooks/useCreditor';
import useSelectedTotal from '../../../../../hooks/useSelectedTotal.js';
import { toCurrency } from '../../../../../util/index.js';

import style from './Transfer.module.scss';

const TransferModal = ({ open, toggle }) => {
  const { name } = useCreditor();
  const { amount: selectedTotal, currency } = useSelectedTotal();

  return (
    <Modal size="full" open={open} toggle={toggle} disableClose className={style.background}>
      <div className={style.body}>
        <Modal.Body>
          <Stack gap="large">
            <Card width={'max'}>
              <Box p="medium">
                <Stack gap="medium">
                  <Stack>
                    <Shelf justifyContent="space-between">
                      <Heading textAlign="left" fontWeight="400" fontSize="heading.2">
                        Bank information
                      </Heading>
                    </Shelf>
                    <Stack>
                      <Text color="Mid Neutral 1">
                        {toCurrency(selectedTotal, currency)} to {name}{' '}
                      </Text>
                      <Details />
                    </Stack>
                  </Stack>
                </Stack>
              </Box>
            </Card>
          </Stack>
        </Modal.Body>
      </div>
    </Modal>
  );
};

export default TransferModal;

TransferModal.propTypes = {
  open: bool,
  toggle: func,
};
