import React, { useContext } from 'react';
import { Card, Text, Shelf, Box, Stack, Button, Tooltip } from 'chaser-components';
import { PaymentContext } from '../../../../providers/paymentContext';
import { toCurrency } from '../../../../util/index';
import pluralize from 'pluralize';

import useCreditor from '../../../../hooks/useCreditor';
import style from './MobileSummary.module.scss';
import useCardDetails from '../../../../hooks/useCardDetails';
import useBankDetails from '../../../../hooks/useBankDetails';
import useSelectedTotal from '../../../../hooks/useSelectedTotal';
import { payment } from '../../../../util/constants';
import { getActiveProvider } from '../../../../hooks/useProvider';

const MobileSummary = () => {
  const { pay } = useContext(PaymentContext);
  const { amount: selectedTotal, currency, numberOfInvoices } = useSelectedTotal();
  const hasBankDetails = !!useBankDetails();
  const { payments } = useCreditor();
  const provider = getActiveProvider(payments);
  const cardDetails = useCardDetails(provider);

  return (
    <Card className={style.summary}>
      <Box p="small">
        <Stack dividers>
          <Stack gap="medium">
            <Shelf justifyContent="space-between">
              <Text data-testid="summary-total-invoices" fontSize="medium" fontWeight="bold">
                Total ({pluralize('invoices', numberOfInvoices, true)})
              </Text>
              <Text data-testid="summary-total" fontSize="medium" fontWeight="bold">
                {toCurrency(selectedTotal, currency)}
              </Text>
            </Shelf>
            <Stack gap="xsmall">
              <Tooltip
                as="span"
                disable={!cardDetails?.isMultiCurrency}
                position="left"
                content="Multi-currency payments are not supported"
              >
                {cardDetails?.cardPaymentActive && (
                  <Button
                    flexGrow="1"
                    as="span"
                    disabled={numberOfInvoices === 0 || cardDetails?.isMultiCurrency}
                    data-testid="pay-card-btn"
                    onClick={() => {
                      pay({
                        provider,
                        paymentType: payment.card,
                      });
                    }}
                    display="flex"
                  >
                    Pay now
                  </Button>
                )}
              </Tooltip>
              {hasBankDetails && (
                <Button
                  flexGrow="1"
                  data-testid="pay-transfer-btn"
                  as="span"
                  onClick={() => {
                    pay({
                      provider: undefined,
                      paymentType: payment.bank,
                    });
                  }}
                  display="flex"
                  appearance={cardDetails ? 'secondary' : 'primary'}
                >
                  See bank transfer information
                </Button>
              )}
            </Stack>
          </Stack>
        </Stack>
      </Box>
    </Card>
  );
};

export default MobileSummary;
