import React from 'react';
import { Button } from 'chaser-components';
import useContact from '../../../hooks/useContact';
import config from '../../../config';

const CustomerStatement = () => {
  const { orgId, contactId } = useContact();

  return (
    <Button
      style={{ padding: '5px' }}
      as="a"
      target="_blank"
      size="narrow"
      href={`${config.apiUrl}/c/${orgId}/d/${contactId}/fullstatementpdf`}
      appearance="secondary"
      icon="download"
    >
      Download statement
    </Button>
  );
};

export default CustomerStatement;
