import React from 'react';
import { Stack, Heading } from 'chaser-components';
import style from './Doorstep.module.scss';

const Doorstep = () => (
  <Stack className={style.center}>
    <Heading>Page not found!</Heading>
  </Stack>
);

export default Doorstep;
