import React, { useContext } from 'react';
import { Card, Box, Heading, Text, Stack, Button } from 'chaser-components';
import { PaymentContext } from '../../../../../../../providers/paymentContext';
import Alert from './alert.svg';

const ErrorMessage = () => {
  const {
    setShowModal,
    paymentOptions: { paymentResult },
  } = useContext(PaymentContext);

  const { message } = paymentResult.error;

  return (
    <Stack gap="medium">
      <Card width={'500px'}>
        <Box p="medium">
          <Stack gap="medium" textAlign="center">
            <img src={Alert} alt="" width={'50px'} style={{ alignSelf: 'center' }} />
            <Heading>Payment failed</Heading>
            <Stack gap="xsmall">
              <Text color="Mid Neutral 1">{message}</Text>
            </Stack>
            <Stack gap="xsmall">
              <Button
                data-testid="cancel-btn"
                onClick={() => setShowModal(false, { reset: false })}
                textAlign="center"
                appearance="secondary"
              >
                Close
              </Button>
            </Stack>
          </Stack>
        </Box>
      </Card>
      <Text textAlign="center" color="Mid Neutral 2"></Text>
    </Stack>
  );
};

export default ErrorMessage;
