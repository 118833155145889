import React, { useContext, useEffect } from 'react';
import { Heading, Text, Stack, Button, Card, Box } from 'chaser-components';
import pluralize from 'pluralize';
import { toCurrency } from '../../../../../../../util/index';
import Check from './check.svg';
import { PaymentContext } from '../../../../../../../providers/paymentContext';
import useDebtor from '../../../../../../../hooks/useDebtor';
import useContact from '../../../../../../../hooks/useContact';
import useCreditor from '../../../../../../../hooks/useCreditor';
import { useHistory } from 'react-router-dom';
import ApiService from '../../../../../../../services/api';
import { useQuery } from 'react-query';
import getDownloadStripeReceiptUrl from '../../../../../../../services/getDownloadStripeReceiptUrl';
import markInvoicesAsPendingPayment from '../../../../../../../services/markInvoicesAsPendingPayment';
import confirmContactDebitAgreement from './confirmContactDebitAgreement';
import {
  ddMandateMapping,
  paymentProviders,
  paymentTypes,
} from '../../../../../../../util/constants';

const getStripePayment = async (_, orgId, paymentIntentId) => {
  const { data } = await ApiService().get(
    `/verifyPayment/${orgId}/${paymentIntentId}?provider=stripe`,
  );
  return data;
};

const SuccessMessage = () => {
  const {
    setShowModal,
    paymentOptions: { paymentResult },
  } = useContext(PaymentContext);

  const history = useHistory();
  const { orgId, contactId } = useContact();
  const { debtorId } = useDebtor();
  const { name } = useCreditor();

  const { data: payment } = useQuery(
    ['payment', orgId, paymentResult?.paymentIntent],
    getStripePayment,
    {
      enabled: paymentResult?.paymentIntent,
    },
  );

  function onDone() {
    history.push({ search: '' });
    setShowModal(false, { reset: false });
    window.location.reload(false);
  }

  const receiptUrl = getDownloadStripeReceiptUrl({
    contactId,
    paymentIntentId: paymentResult?.paymentIntent,
    orgId,
  });

  const isDirectDebit = [
    paymentTypes.bacs_debit,
    paymentTypes.sepa_debit,
    paymentTypes.us_bank_account,
  ].includes(payment.payment_method_types[0]);

  useEffect(() => {
    if (!payment) return;

    const paymentType = isDirectDebit ? payment.payment_method_types[0] : paymentTypes.card;

    markInvoicesAsPendingPayment(
      payment.paymentId,
      paymentProviders.stripe,
      paymentResult?.paymentIntent,
      paymentType,
    );

    if (isDirectDebit) {
      confirmContactDebitAgreement(debtorId, payment.currency);
    }
  }, [isDirectDebit, payment, debtorId, paymentResult]);

  return (
    <Stack gap="medium">
      <Card width={'500px'}>
        <Box p="medium">
          <Stack gap="medium" textAlign="center">
            <img src={Check} alt="" width={'50px'} style={{ alignSelf: 'center' }} />
            {!isDirectDebit && (
              <>
                <Heading>Payment successful</Heading>
                <Stack gap="small">
                  <Text color="Mid Neutral 1">To {name}</Text>
                  <Text color="Mid Neutral 1">
                    {pluralize('invoice', parseInt(payment?.totalInvoicesPaid), true)}
                  </Text>
                  <Text color="Mid Nuetral 1">
                    {toCurrency(payment?.amount || 0, payment?.currency || 'gbp')}
                  </Text>
                </Stack>
              </>
            )}
            {isDirectDebit && (
              <>
                <Heading>Set up successful</Heading>
                <Text color="Mid Neutral 1">
                  {`${ddMandateMapping[payment?.currency?.toLowerCase()].name} ${
                    payment?.currency?.toLowerCase() === 'usd' ? '' : 'Direct'
                  } Debit`}{' '}
                  with {name}
                </Text>
              </>
            )}
            <Stack gap="xsmall">
              <Button data-testid="done-btn" style={{ width: '100%' }} onClick={onDone}>
                Done
              </Button>
              {!payment.payment_method_types.includes('bacs_debit') && (
                <Button
                  as="a"
                  data-testid="receipt-btn"
                  target="_blank"
                  href={receiptUrl}
                  style={{ width: '100%' }}
                  appearance="secondary"
                >
                  Download receipt
                </Button>
              )}
            </Stack>
          </Stack>
        </Box>
      </Card>
      <Text textAlign="center" color="Mid Neutral 2">
        {'Powered by Stripe payments'}
      </Text>
    </Stack>
  );
};

export default SuccessMessage;
