import React from 'react';
import { Button } from 'chaser-components';
import InvoicePDF from './InvoicePDF/index.jsx';
import CustomerStatement from './CustomerStatement/index';

const DownloadButton = ({ ...props }) => (
  <Button
    style={{ padding: '5px' }}
    size="narrow"
    appearance="secondary"
    icon="download"
    {...props}
  />
);

DownloadButton.InvoicePDF = InvoicePDF;
DownloadButton.CustomerStatement = CustomerStatement;

export default DownloadButton;
