import { Button, ButtonGroup, Icon, Shelf, Text } from 'chaser-components';
import { Cell, Table } from '../../../../components/InvoiceTable/index.js';
/* eslint-disable react/prop-types */
import React, { useContext, useEffect, useMemo } from 'react';

import DownloadButton from '../../../../components/DownloadButton/index.jsx';
import { PaymentContext } from '../../../../providers/paymentContext';
import cx from 'classnames';
import { formatDate } from '../../../../util/time';
import fromPairs from 'lodash/fromPairs';
import pluralize from 'pluralize';
import pullAt from 'lodash/pullAt';
import style from './Outstanding.module.scss';
import { toCurrency, enabledPaymentsHandler, showButtonForPendingDebit } from '../../../../util';
import { payment, paymentProviders, paymentTypes } from '../../../../util/constants';
import useUnpaidInvoices from '../../../../hooks/useUnpaidInvoices.js';
import useMedia from '../../../../hooks/useMedia';
import useCreditor from '../../../../hooks/useCreditor.js';
import useProvider from '../../../../hooks/useProvider';

const Due = ({ date }) => {
  const todaysDate = new Date();
  todaysDate.setHours(0, 0, 0, 0);
  const invoiceDueDate = new Date(date);
  const overDueDays = Math.round((todaysDate - invoiceDueDate) / (1000 * 60 * 60 * 24));
  const isOverDue = overDueDays > 0;
  const days = Math.abs(overDueDays);
  const dueToday = days === 0;

  return (
    <Text color={isOverDue ? 'Red' : undefined}>
      {dueToday ? (
        'due today'
      ) : (
        <>
          {pluralize('days', days, true)} {isOverDue ? 'overdue' : 'until due'}
        </>
      )}
    </Text>
  );
};

const InstalmentsTable = ({ invoice, pay, provider }) => {
  const { number } = invoice.instalments?.find(i => !i.isPaid) || 1;
  const nextInstalmentIndex = number - 1;

  return (
    <table className={style.instalmentsTable}>
      {invoice.instalments?.map((inst, i) => {
        return (
          <div className={style.instalmentsRow}>
            <div>
              <Text color={inst.isPaid && 'Mid Neutral 1'}>Instalment {inst.number}</Text>
            </div>
            <div>
              <Text color={inst.isPaid && 'Mid Neutral 1'}>
                {formatDate(inst.dueDate, { month: 'short' })}
              </Text>
            </div>
            <div>
              <Text color={inst.isPaid && 'Green'}>
                {inst.isPaid ? 'Paid' : <Due date={inst.dueDate} />}
              </Text>
            </div>
            <div align="right">
              <Text color={inst.isPaid && 'Mid Neutral 1'}>
                {toCurrency(inst.amountDue, invoice.CurrencyCode)}
              </Text>
            </div>
            <div align="right">
              {i === nextInstalmentIndex &&
                (invoice.cardPaymentActive || showButtonForPendingDebit(invoice)) && (
                  <Button
                    onClick={e => {
                      e.stopPropagation();
                      pay({
                        paymentType: payment.card,
                        provider,
                        invoiceIds: {
                          [invoice._id]: {
                            [i]: true,
                          },
                        },
                      });
                    }}
                    data-testid={`pay-${invoice.InvoiceNumber}-instalment-btn`}
                    size="narrow"
                    appearance="secondary"
                    icon="check"
                  >
                    Pay now
                  </Button>
                )}
            </div>
          </div>
        );
      })}
    </table>
  );
};

const getPaymentButtonText = invoice => {
  const isPaymentPending = invoice.paymentPending?.isPaymentPending;
  const provider = invoice.paymentPending?.provider?.toUpperCase();
  const paymentMethod = invoice.paymentPending?.paymentMethod;

  if (!isPaymentPending) {
    return 'Pay now';
  }

  if (
    isPaymentPending &&
    provider === paymentProviders.stripe &&
    [paymentTypes.bacs_debit, paymentTypes.sepa_debit, paymentTypes.us_bank_account].includes(
      paymentMethod,
    )
  ) {
    return 'Pending debit';
  }

  if (isPaymentPending && paymentMethod === paymentTypes.card) {
    return 'Processing Payment';
  }

  if (
    isPaymentPending &&
    (provider === paymentProviders.stripe || provider === paymentProviders.unipaas)
  ) {
    return 'Processing payment';
  }

  return 'Pay now';
};

const Outstanding = () => {
  const {
    setPaymentOptions,
    pay,
    paymentOptions: { selectedInvoiceIds },
  } = useContext(PaymentContext);
  const { accountingSoftware } = useCreditor();
  const { invoices, isLoading, provider } = useUnpaidInvoices();
  const isMobile = useMedia('(max-width: 1000px)');
  const paymentProvider = useProvider();
  const paymentsByCardEnabledHandler =
    enabledPaymentsHandler[paymentProvider?.provider?.toUpperCase()];

  const data = useMemo(
    () =>
      !invoices
        ? []
        : invoices
            .sort((a, b) => new Date(a.DueDate) - new Date(b.DueDate))
            .map((invoice, index) => {
              const instalments = invoice.paymentPlan?.instalments.map((inst, i) => ({
                ...inst,
                isPaid: inst.amountDue <= 0,
                number: i + 1,
                total: invoice.paymentPlan.instalments.length,
              }));

              return {
                ...invoice,
                instalments,
                hasInstalmentOverdue: instalments?.some(
                  inst => !inst.isPaid && new Date() > new Date(inst.dueDate),
                ),
                key: index,
              };
            }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [invoices],
  );
  const allWithoutPaymentPending = {};
  data.forEach(i => {
    if (i.paymentPending?.isPaymentPending) {
      allWithoutPaymentPending[i._id] = true;
    }
    if (
      paymentsByCardEnabledHandler &&
      !paymentsByCardEnabledHandler({
        paymentProvider,
        selectedInvoiceCurrency: i.CurrencyCode,
        accountWithoutRestrictions: true,
      })
    ) {
      allWithoutPaymentPending[i._id] = true;
    }
  });

  useEffect(() => {
    if (Object.keys(selectedInvoiceIds).length) return;

    const allWithoutPaymentPending = fromPairs(
      data.map(i => [
        i._id,
        i.paymentPending?.isPaymentPending
          ? false
          : paymentsByCardEnabledHandler &&
            !paymentsByCardEnabledHandler({
              paymentProvider,
              selectedInvoiceCurrency: i.CurrencyCode,
              accountWithoutRestrictions: true,
            })
          ? false
          : true,
      ]),
    );

    setPaymentOptions({ selectedInvoiceIds: allWithoutPaymentPending });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const selectedIdsWithoutInstalments = fromPairs(
    Object.entries(selectedInvoiceIds).filter(([k, v]) => v === true),
  );

  const thTextInMobile = {
    textTransform: !isMobile ? '' : 'uppercase',
    fontSize: !isMobile ? '' : '11px',
  };

  const handlePayment = (invoice, provider) => {
    pay({
      provider,
      paymentType: payment.card,
      invoiceIds: { [invoice._id]: true },
    });
  };

  function formatNumber(number, isMobile) {
    const maxLength = isMobile ? 20 : 40;
    if (number && number.length > maxLength) {
      return `${number.slice(0, maxLength)}...`;
    }
    return number;
  }

  const columns = [
    {
      accessor: 'InvoiceNumber',
      headerStyle: { width: !isMobile ? '16%' : '27%', ...thTextInMobile },
      header: 'No.',
      cell: (number, invoice) => (
        <Cell>
          <Shelf gap="xxsmall" flexWrap="nowrap">
            <Text>{formatNumber(number, isMobile)}</Text>
            {invoice.paymentPlan && <Icon name="arrow-down" size={8} />}
          </Shelf>
        </Cell>
      ),
    },
    {
      accessor: 'DueDate',
      headerStyle: { width: !isMobile ? '15%' : '35%', ...thTextInMobile },
      header: 'Due Date',
      cell: (DueDate, invoice) => (
        <Cell>
          <Text>{formatDate(invoice.instalment?.dueDate ?? DueDate, { month: 'short' })}</Text>
        </Cell>
      ),
    },
    {
      accessor: 'debtor.status',
      headerStyle: { width: '20%' },
      header: 'Status',
      cell: (status, invoice) => {
        if (invoice.instalments) {
          return (
            <Cell>
              <Text color={invoice.hasInstalmentOverdue && 'Red'}>On payment plan</Text>
            </Cell>
          );
        }

        return (
          <Cell>
            <Due date={invoice.DueDate} />
          </Cell>
        );
      },
    },

    {
      accessor: 'AmountDue',
      header: 'Outstanding',
      align: 'right',
      headerStyle: { textAlign: 'right', ...thTextInMobile },
      headerAlign: 'right',
      cell: (total, invoice) => {
        return (
          <Cell>
            <Text>{toCurrency(total, invoice.CurrencyCode)}</Text>
          </Cell>
        );
      },
    },
    {
      accessor: '_',
      align: 'right',
      headerStyle: { textAlign: 'right', width: '20%' },
      headerAlign: 'right',
      cell: (_, invoice) => {
        return (
          <Cell>
            <ButtonGroup justifyContent="flex-end" flexWrap="nowrap">
              <DownloadButton.InvoicePDF
                accountingSoftware={accountingSoftware}
                invoicePdfLink={invoice.invoicePdfLink}
                invoiceId={invoice._id}
                invoiceNumber={invoice.InvoiceNumber}
              />
              {!isMobile && (invoice.cardPaymentActive || showButtonForPendingDebit(invoice)) && (
                <Button
                  disabled={invoice.paymentPending?.isPaymentPending}
                  onClick={e => {
                    e.preventDefault();
                    handlePayment(invoice, provider);
                  }}
                  data-testid={`pay-${invoice.InvoiceNumber}-btn`}
                  size="narrow"
                  width="120px"
                  appearance="secondary"
                  icon={invoice.paymentPending?.isPaymentPending ? '' : 'check'}
                  style={{
                    cursor: invoice.paymentPending?.isPaymentPending ? 'default' : 'pointer',
                    fontFamily: 'Nunito Sans',
                  }}
                >
                  {getPaymentButtonText(invoice)}
                </Button>
              )}
            </ButtonGroup>
          </Cell>
        );
      },
    },
  ];

  const availableColumns = isMobile ? pullAt(columns, [0, 1, 3, 4]) : columns;
  return (
    <Table
      loading={isLoading}
      keyField="_id"
      data={data}
      columns={availableColumns}
      noDataIndication="No invoices"
      rowClassName={row => cx(style.row, !row.paymentPlan && style.cantExpand)}
      renderExpanded={row => <InstalmentsTable invoice={row} pay={pay} provider={provider} />}
      selected={selectedIdsWithoutInstalments}
      disabled={allWithoutPaymentPending}
      setSelected={s =>
        setPaymentOptions({
          selectedInvoiceIds: s,
        })
      }
    />
  );
};

export default Outstanding;
