import React, { useContext } from 'react';
import { paymentProviders, paymentTypes } from '../../../../util/constants';
import useCreditor from '../../../../hooks/useCreditor';
import { getActiveProvider } from '../../../../hooks/useProvider';
import Stripe from './Stripe/index.jsx';
import Unipaas from './Unipaas/index.jsx';
import Transfer from './Transfer/index.jsx';
import { PaymentContext } from '../../../../providers/paymentContext';

const PaymentModal = () => {
  const { paymentOptions } = useContext(PaymentContext);
  const { showModal, setShowModal, paymentType } = paymentOptions;
  const { payments } = useCreditor();
  const activeProvider = getActiveProvider(payments);

  if (paymentType === paymentTypes.bank) return <Transfer open={showModal} toggle={setShowModal} />;

  switch (activeProvider) {
    case paymentProviders.unipaas:
      return <Unipaas open={showModal} toggle={setShowModal} />;
    default:
      return <Stripe open={showModal} toggle={setShowModal} />;
  }
};

export default PaymentModal;
