let config = {};

try {
  const environment = process.env.REACT_APP_ENV || 'production';
  if (environment !== 'test') {
    console.info(`Loading application config for environment: ${environment}`);
  }
  config = require(`./${environment}.js`); // eslint-disable-line
} catch (e) {
  console.error('No application config could be found.');
}

module.exports = config;
