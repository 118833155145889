/* eslint-disable no-console */

const logEnvironments = ['local', 'development'];
const isDebug = logEnvironments.includes(process.env.REACT_APP_ENV);

let logLevel = '0';

const initLogging = () => {
  if (isDebug) {
    const urlParams = new URLSearchParams(window.location.search);
    logLevel = urlParams.get('logLevel') || '0';
    console.log('Logging level:', logLevel);
  }
};

const logger = (loggerType, ...args) => {
  if (isDebug && logLevel === '1') {
    return console[loggerType](...args);
  }
  return () => {};
};

const logging = {
  debug: (...args) => logger('debug', ...args),
  error: (...args) => logger('error', ...args),
  info: (...args) => logger('info', ...args),
  log: (...args) => logger('log', ...args),
  trace: (...args) => logger('trace', ...args),
  warn: (...args) => logger('warn', ...args),
};

module.exports = {
  initLogging,
  logging,
};
