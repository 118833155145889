import {
  ButtonGroup,
  Heading,
  Shelf,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from 'chaser-components';
import React from 'react';
import Contacts from './components/Contacts/index';
import DownloadButton from '../../components/DownloadButton/index.jsx';
import MobileSummary from './components/MobileSummary';
import Note from './components/Note/index';
import Outstanding from './components/Outstanding/index';
import PageContainer from '../../components/PageContainer/index';
import Paid from './components/Paid/index';
import { PaymentProvider } from '../../providers/paymentContext';
import style from './Overview.module.scss';
import useDebtor from '../../hooks/useDebtor';
import useMedia from '../../hooks/useMedia';
import Summary from './components/Summary';
import PaymentModal from './components/PaymentModal/index';
import Advert from '../../components/Advert';
import useCreditor from '../../hooks/useCreditor.js';
const Overview = () => {
  const { name } = useDebtor();
  const { hideAdvert } = useCreditor();
  const isMobile = useMedia('(max-width: 1000px)');

  return (
    <PageContainer className={style.overview}>
      <PaymentProvider>
        <Shelf alignItems="top" justifyContent="space-between" gap="medium" flexWrap="nowrap">
          <Stack gap={!isMobile ? 'large' : 'small'} width="100%">
            <Stack flexDirection={['column', 'row']} justifyContent="space-between">
              <Heading color="Dark Neutral 1" fontWeight="bold">
                {name}{' '}
              </Heading>
              <ButtonGroup>
                <DownloadButton.CustomerStatement />
              </ButtonGroup>
            </Stack>
            {isMobile && <Note />}
            <Tabs as={Stack}>
              <TabList>
                <Tab>Outstanding</Tab>
                <Tab>Paid</Tab>
              </TabList>
              <TabPanels>
                <TabPanel>
                  <Outstanding />
                </TabPanel>
                <TabPanel>
                  <Paid />
                </TabPanel>
              </TabPanels>
            </Tabs>
          </Stack>
          {!isMobile && (
            <div className={style.controls}>
              <Stack as="div">
                <Summary />
                <Contacts />
                <Advert hideAdvert={hideAdvert} />
                <Note />
              </Stack>
            </div>
          )}
        </Shelf>
        {isMobile && <MobileSummary />}
        <PaymentModal />
      </PaymentProvider>
    </PageContainer>
  );
};

export default Overview;
