import React, { createContext, useState } from 'react';
import { node } from 'prop-types';
import useQueryParams from '../hooks/useQueryParams';
import { payment, paymentProviders } from '../util/constants';

const PaymentContext = createContext();

const paymentErrorDefaults = {
  message: undefined,
  submessage: undefined,
  refetch: undefined,
  isLoading: false,
  reset: true,
};

const PaymentProvider = ({ children }) => {
  const { payment_intent } = useQueryParams();
  const { authorizationId } = useQueryParams();

  const successResult = payment_intent || authorizationId;

  const [paymentOptions, setPaymentOptions] = useState({
    showModal: false,
    showModalDirectDebitOverview: false,
    provider: paymentProviders.stripe,
    paymentType: payment.card,
    selectedInvoiceIds: {},
    payingInvoiceIds: {},
    paymentResult: {
      authorizationId: null,
      success: !!successResult,
      error: false,
    },
  });

  const ctx = {
    paymentOptions,
    setPaymentOptions: options =>
      setPaymentOptions(prev => ({
        ...prev,
        ...options,
      })),
    pay: options =>
      setPaymentOptions(prev => ({
        ...prev,
        ...options,
        paymentResult: undefined,
        payingInvoiceIds: options.invoiceIds || prev.selectedInvoiceIds,
        showModal: true,
      })),
    setShowModal: (showModal, opts = { reset: true }) =>
      setPaymentOptions(prev => ({
        ...prev,
        paymentResult: opts.reset ? undefined : prev.paymentResult,
        showModal,
      })),
    setShowModalDirectDebitOverview: showModalDirectDebitOverview =>
      setPaymentOptions(prev => ({
        ...prev,
        showModalDirectDebitOverview,
      })),
    setPaymentResult: result =>
      setPaymentOptions(prev => ({
        ...prev,
        paymentResult: result,
      })),
    setPaymentError: (initialPaymentError = paymentErrorDefaults) =>
      setPaymentOptions(prev => ({
        ...prev,
        paymentResult: {
          error: {
            ...initialPaymentError,
            success: false,
          },
        },
      })),
    setPaymentSuccess: () =>
      setPaymentOptions(prev => ({
        ...prev,
        paymentResult: {
          paymentIntent: payment_intent,
          authorizationId: authorizationId,
          success: true,
        },
        showModal: true,
      })),
  };

  return <PaymentContext.Provider value={ctx}>{children}</PaymentContext.Provider>;
};

PaymentProvider.propTypes = {
  children: node,
};

export { PaymentContext, PaymentProvider };
