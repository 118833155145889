import React from 'react';
import { paymentProviders } from '../../../../util/constants';
import useCreditor from '../../../../hooks/useCreditor';
import { getActiveProvider } from '../../../../hooks/useProvider';
import Stripe from './Stripe/index.jsx';
import Unipaas from './Unipaas/index.jsx';

const Summary = () => {
  const { payments } = useCreditor();
  const activeProvider = getActiveProvider(payments);

  return <>{activeProvider === paymentProviders.unipaas ? <Unipaas /> : <Stripe />}</>;
};

export default Summary;
