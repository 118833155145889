import { useLocation } from 'react-router-dom';
import { parse } from 'query-string';
import mapValues from 'lodash/mapValues';

function useQueryParams() {
  const search = useLocation().search;
  const queries = parse(search);

  return mapValues(queries, val => {
    if (val === 'false') return false;
    if (val === 'true') return true;
    return val;
  });
}

export default useQueryParams;
