import useCreditor from './useCreditor';
import useProvider from './useProvider';
import useSelectedTotal from './useSelectedTotal';
import { paymentEnabled, hasDeprecatedAccount } from '../util';

function useCardDetails(fromProvider) {
  const { baseCurrency, accountingSoftware } = useCreditor();
  const paymentProvider = useProvider(fromProvider);
  const { invoices, currency } = useSelectedTotal();
  const isMultiCurrency = [...new Set(invoices.map(p => p.CurrencyCode))].length > 1;

  if (hasDeprecatedAccount(baseCurrency, paymentProvider, invoices)) {
    return {
      isMultiCurrency,
      cardPaymentActive: true,
      ...paymentProvider,
    };
  }

  return {
    isMultiCurrency,
    cardPaymentActive: paymentEnabled(paymentProvider, invoices, currency, accountingSoftware),
    ...paymentProvider,
  };
}

export default useCardDetails;
