/* eslint-disable react/sort-comp, no-console */
import { node, object, bool } from 'prop-types';
import React, { Component } from 'react';
import * as Sentry from '@sentry/browser';
import cx from 'classnames';
import { Box, Stack, Button, Text } from 'chaser-components';
import { withRouter } from 'react-router';
import style from './ErrorBoundary.module.scss';
import { getErrorMessage } from '../../util/errors';
import ApiError from '../../util/ApiError';

class ErrorBoundary extends Component {
  static propTypes = {
    children: node.isRequired,
    history: object.isRequired,
    center: bool,
  };

  static defaultProps = {
    center: false,
  };

  state = {
    error: null,
    eventId: null,
    feedbackModalOpen: false,
  };

  static getDerivedStateFromError(error) {
    if (error instanceof Error) {
      return { error };
    }

    return null;
  }

  componentDidCatch(err, info) {
    if (err.log === false) return;
    Sentry.withScope(scope => {
      if (err.info) scope.setExtras(err.info);
      scope.setLevel(Sentry.Severity.Fatal);
      scope.setExtras(info);
    });
  }

  componentDidMount() {
    this.retry();
    this.unlisten = this.props.history.listen(() => {
      if (this.state.error) {
        this.setState({ error: null });
      }
    });
    window.addEventListener('online', this.retry);
  }

  componentWillUnmount() {
    this.unlisten();
    window.removeEventListener('online', this.retry);
  }

  retry = () => {
    const { error } = this.state;
    if (error) {
      if (error.retry) {
        error.retry();
      }
    }
  };

  renderError() {
    const { error } = this.state;
    const message = getErrorMessage(error);
    let button = <Button onClick={() => window.location.reload()}>Reload</Button>;

    if (error instanceof ApiError && error.action) {
      button = <Button onClick={error.action.callback}>{error.action.label}</Button>;
    }

    return (
      <>
        <Text fontSize="medium">{message}</Text>
        {button}
      </>
    );
  }

  render() {
    const { error } = this.state;
    const { children, center } = this.props;

    return error ? (
      <Box p="medium" className={cx(center && style.center)}>
        <Stack
          gap="medium"
          style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}
        >
          {this.renderError()}
        </Stack>
      </Box>
    ) : (
      children
    );
  }
}

export default withRouter(ErrorBoundary);
