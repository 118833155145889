import { useQuery } from 'react-query';
import ApiService from '../services/api';
import useContact from './useContact';
import useDebtor from './useDebtor';

const getCreditor = async (_, orgId) => {
  const { data } = await ApiService().get(`/creditor/${orgId}`);
  return data;
};

function useCreditor(id) {
  const { defaultCurrency } = useDebtor();
  const { orgId } = useContact();
  const { data } = useQuery(['creditor', id || orgId], getCreditor, {
    enabled: id || orgId,
  });

  const baseCurrency = defaultCurrency || data?.BaseCurrency;

  return {
    ...data,
    baseCurrency,
  };
}

export default useCreditor;
