import React from 'react';
import { Table } from 'chaser-components';
import style from './InvoiceTable.module.scss';
import { array, string } from 'prop-types';

const InvoiceTable = ({ columns = [], data = [], noDataIndication = 'No data', ...rest }) => (
  <Table
    className={style.table}
    rowClassName={style.row}
    keyField="_id"
    data={data}
    columns={columns}
    noDataIndication={noDataIndication}
    {...rest}
  />
);

export default InvoiceTable;

InvoiceTable.propTypes = {
  columns: array,
  data: array,
  noDataIndication: string,
};
