import useCreditor from './useCreditor';
import { paymentProviders, unipaasOnboardingStatus } from '../util/constants';

const getActiveProvider = (payments, provider) => {
  if (provider) {
    return provider;
  }

  if (!payments && !payments.cardDetails) {
    return null;
  }

  const chaserPay =
    Array.isArray(payments.cardDetails) &&
    payments.cardDetails.find(c => c.provider === paymentProviders.unipaas);
  const hasChaserPayEnabled =
    chaserPay && unipaasOnboardingStatus.includes(chaserPay.status) && (chaserPay.enabled ?? false);

  if (hasChaserPayEnabled) {
    return paymentProviders.unipaas;
  }

  const hasStripeConnected =
    Array.isArray(payments.cardDetails) &&
    payments.cardDetails.find(c => c.provider === paymentProviders.stripe);

  if (hasStripeConnected) {
    return paymentProviders.stripe;
  }

  return null;
};

function useProvider(provider) {
  const { payments } = useCreditor();
  const activeProvider = getActiveProvider(payments, provider);

  if (!activeProvider) {
    return null;
  }

  return (
    payments &&
    payments.cardDetails &&
    payments.cardDetails.find(c => c.provider === activeProvider)
  );
}

export { getActiveProvider };
export default useProvider;
