import { useQuery } from 'react-query';
import ApiService from '../services/api';

const getRates = async () => {
  const { data } = await ApiService().get('/rates');
  return data;
};

function useRates() {
  const { data } = useQuery(['rates'], getRates, {
    staleTime: 30 * 60 * 1000,
  });
  return data;
}

export default useRates;
