import ApiService from './api';

async function markInvoicesAsPendingPayment(paymentId, provider, providerPaymentId, paymentMethod) {
  const dataToChange = {
    isPaymentPending: true,
    noChase: true,
    provider,
    providerPaymentId,
    paymentMethod,
  };
  const { data } = await ApiService().patch(`/pendingpayments/${paymentId}/invoices`, dataToChange);
  return data;
}

export default markInvoicesAsPendingPayment;
