import { useContext } from 'react';
import { PaymentContext } from '../providers/paymentContext';
import { calculateTotal } from '../util';
import useCreditor from './useCreditor';
import useUnpaidInvoices from './useUnpaidInvoices';
import useRates from './useRates';

function isObjectAndHasProperties(obj) {
  return typeof obj === 'object' && obj !== null && Object.keys(obj).length > 0;
}

function useSelectedTotal(paying = false) {
  const { invoices } = useUnpaidInvoices();
  const { baseCurrency } = useCreditor();
  const {
    paymentOptions: { selectedInvoiceIds, payingInvoiceIds },
  } = useContext(PaymentContext);
  const invoiceIds = paying ? payingInvoiceIds : selectedInvoiceIds;
  const rates = useRates();
  const selectedFullInvoices = invoices ? invoices.filter(i => invoiceIds[i._id] === true) : [];
  const invoicesToAnalyse = invoices ?? [];

  const selectedPartialInvoices = invoicesToAnalyse
    .filter(inv => isObjectAndHasProperties(invoiceIds[inv._id]))
    .map(inv => {
      const { instalments } = inv.paymentPlan;
      const selected = invoiceIds[inv._id];
      const numberOfInstalments = Object.keys(selected).length;
      const amount = instalments
        .filter((_, i) => selected[i])
        .reduce((acc, cur) => acc + cur.amountDue, 0);
      return {
        _id: inv._id,
        InvoiceNumber: inv.InvoiceNumber,
        CurrencyCode: inv.CurrencyCode,
        instalmentsTotal: amount,
        numberOfInstalments,
        isPartial: true,
      };
    });

  const numberOfInstalments = selectedPartialInvoices.reduce(
    (acc, cur) => acc + cur.numberOfInstalments,
    0,
  );

  const combined = [...selectedPartialInvoices, ...selectedFullInvoices];
  const [combinedTotal, combinedCurrency] = calculateTotal(combined, rates, baseCurrency);
  const [fullInvoicesTotal] = calculateTotal(selectedFullInvoices, rates, baseCurrency);

  return {
    invoices: combined,
    numberOfInvoices: combined.length,
    fullInvoices: selectedFullInvoices,
    fullInvoicesAmount: fullInvoicesTotal,
    numberOfInstalments,
    amount: combinedTotal,
    currency: combinedCurrency,
  };
}

export default useSelectedTotal;
