import React, { useLayoutEffect } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import LoadBoundary from './components/LoadBoundary/index';
import NavigationBar from './components/NavigationBar/index';
import Overview from './app/overview/index';
import Doorstep from './app/doorstep/index';
import { initLogging } from './util/logging';
import { matchPath, useLocation } from 'react-router';

function App() {
  const { pathname } = useLocation();
  const { params } =
    matchPath(pathname, {
      path: ['/c/:orgId/d/:debtorId', '/creditor/:orgId/debtor/:debtorId'],
    }) || {};

  const { orgId, debtorId } = params || {};

  useLayoutEffect(() => {
    initLogging();
  }, [orgId, debtorId]);

  return (
    <>
      <header>
        <NavigationBar />
      </header>
      <main>
        <LoadBoundary center>
          <Switch>
            <Route
              path={['/c/:orgId/d/:contactId', '/creditor/:orgId/debtor/:contactId']}
              component={Overview}
            />
            <Route path="/404" component={Doorstep} />
            <Route
              path="/"
              exact
              component={() => {
                window.location.replace('https://www.chaserhq.com/features/payment-portal');
                return null;
              }}
            />
            <Redirect from="*" to="/404" />
          </Switch>
        </LoadBoundary>
      </main>
    </>
  );
}

export default App;
