import { node, string } from 'prop-types';
import React from 'react';
import { Container } from 'chaser-components';

const PageContainer = ({ children, ...rest }) => (
  <Container {...rest}>
    {children}
  </Container>
);

PageContainer.propTypes = {
  children: node,
  className: string,
};

export default PageContainer;
