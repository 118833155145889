import colorMap from '../../mixins/colorMap';

const styles = {
  theme: 'none',
  rules: {
    '.Input, .Tab': {
      borderRadius: '6px',
      boxShadow: 'none',
      border: `1px solid ${colorMap['Mid Neutral 3']}`,
    },
    '.Input--empty, .Tab--empty': {
      borderRadius: '6px',
      boxShadow: 'none',
      border: `1px solid ${colorMap['Mid Neutral 3']}`,
    },
    '.Input:hover, .Tab:hover': {
      borderColor: colorMap['Mid Neutral 2'],
    },
    '.Input--invalid': {
      border: `1px solid ${colorMap['Red']}`,
      borderColor: colorMap['Red'],
    },
    '.Input:focus, .Tab:focus': {
      borderColor: colorMap['Blue'],
      boxShadow: `inset 0 0 0 1px ${colorMap['Blue']}`,
    },
  },
  variables: {
    borderRadius: '6px',
  },
};

export default styles;
