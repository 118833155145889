import ApiError from './ApiError';

// chaser-codes
const errors = {
};

function getErrorMessage(err, fallback = 'Something went wrong.') {
  if (navigator.onLine === false) return 'You appear to be offline.';
  if (err.code && errors[err.code]) {
    const m = errors[err.code];
    return typeof m === 'function' ? m(err.meta) : m;
  }
  return (err instanceof ApiError && err.message) || fallback;
}

export { getErrorMessage };
