import React, { useContext } from 'react';
import CheckoutForm from '../../../../../../../components/PaymentProviders/CheckoutForm';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import config from '../../../../../../../config/index';
import getPaymentIntent from '../../../../../../../services/getPaymentIntent';
import useContact from '../../../../../../../hooks/useContact';
import { PaymentContext } from '../../../../../../../providers/paymentContext';
import usePush from '../../../../../../../hooks/usePush';
import { useOnMount } from 'chaser-components';
import useSelectedTotal from '../../../../../../../hooks/useSelectedTotal';
import useCardDetails from '../../../../../../../hooks/useCardDetails';
import LoadingMessage from '../../../../../../../components/LoadingMessage';
import { paymentProviders } from '../../../../../../../util/constants';

const ByCard = () => {
  const { setShowModal, setPaymentError } = useContext(PaymentContext);
  const { invoices, currency } = useSelectedTotal(true);
  const { orgId, contactId } = useContact();
  const cardDetails = useCardDetails(paymentProviders.stripe);

  const { response: clientSecret, execute, loading } = usePush(async () => {
    const invoicesMapped = invoices.map(i => ({
      id: i._id,
      amount: i.isPartial ? i.instalmentsTotal : i.AmountDue,
    }));

    if (!invoices || invoices.length === 0) return;

    const result = await getPaymentIntent({
      orgId,
      contactId,
      currency,
      invoices: invoicesMapped,
      paymentType: 'card',
    });
    return result.data.client_secret;
  });

  async function doIt() {
    const [err] = await execute();

    if (err) {
      setPaymentError({
        message: 'There was an issue processing your payment.',
      });
    }
  }

  useOnMount(() => {
    doIt();
  });

  const stripePromise = loadStripe(config.stripeKey, {
    stripeAccount: cardDetails.accountId,
  });

  if (loading) return <LoadingMessage />;

  return (
    <Elements stripe={stripePromise} options={{ clientSecret }}>
      <CheckoutForm
        onCancel={() => setShowModal(false)}
        isLoading={loading}
        setPaymentError={setPaymentError}
        isDebit={false}
        clientSecret={clientSecret}
      />
    </Elements>
  );
};

export default ByCard;
