import axios from 'axios';
import { get } from 'lodash';
import config from '../config/index';
import ApiError from '../util/ApiError';
import history from '../util/history';

function create(path, { headers: headerOverrides } = {}) {
  const baseURL = `${config.apiUrl}${path}`;
  const headers = { 'Content-Type': 'application/json' };

  Object.assign(headers, headerOverrides);

  const instance = axios.create({ baseURL, headers });

  instance.interceptors.response.use(
    response => response,
    error => {
      const response = error?.response || {};
      const { status } = response;

      if (status === 403) {
        // if code is incorrect redirect to 404
        history.push('/404');
      } else {
        if (response.data.error) {
          throw new ApiError(response.data.error);
        }

        throw new ApiError(error.message, {
          info: {
            data: error,
            query: response.config?.data,
          },
        });
      }
      // additional info for Sentry
      error.info = {
        data: get(response, 'data.errors'),
        query: get(response, 'config.data'),
      };
      throw error;
    },
  );

  return instance;
}

function ApiService(options) {
  return create('/', options);
}

export default ApiService;
