import { useCallback, useEffect, useRef } from 'react';
import * as Sentry from '@sentry/browser';
import axios from 'axios';
import { useId } from 'react';
import { toast } from '../components/ToastContainer';
import useLoadingState from './useLoadingState';
import useEventCallback from './useEventCallback';

const defaultOpts = {
  errorToast: null,
  successToast: null,
};

function usePush(endpoint, opts = {}) {
  const id = useId();
  const options = {
    ...defaultOpts,
    ...opts,
  };
  const { successToast, errorToast } = options;

  const {
    response,
    success,
    loading,
    error,
    dispatchLoad,
    dispatchError,
    dispatchSuccess,
    dispatchReset,
  } = useLoadingState();
  const mounted = useRef(true);
  const inFlight = useRef();

  const execute = useEventCallback((...args) => {
    dispatchLoad();
    const thenable = endpoint(...args);
    inFlight.current = thenable;
    return thenable
      .then(data => {
        if (thenable === inFlight.current && mounted.current) {
          dispatchSuccess(data);
        }
        const toastMessage = typeof successToast === 'function' ? successToast(data) : successToast;
        if (toastMessage) {
          toast.success(toastMessage, { toastId: id });
        }
        return [undefined, data, thenable !== inFlight.current];
      })
      .catch(err => {
        const errorMessage = err.message;
        if (thenable === inFlight.current && mounted.current) {
          dispatchError(errorMessage);
        }
        const toastMessage = typeof errorToast === 'function' ? errorToast(err) : errorToast;
        if (toastMessage) {
          let message, options;
          if (toastMessage === true) {
            message = errorMessage;
          } else if (typeof toastMessage === 'string') {
            message = toastMessage;
          } else {
            ({ message, ...options } = toastMessage);
          }

          toast.error(message, { toastId: id, ...options });
        }
        if (process.env.NODE_ENV !== 'test' && !axios.isCancel(err)) {
          console.error(err);
          Sentry.withScope(scope => {
            if (err.info) scope.setExtras(err.info);
            Sentry.captureException(err);
          });
        }
        return [err, undefined, thenable !== inFlight.current];
      });
  });

  const reset = useCallback(() => {
    inFlight.current = null;
    dispatchReset();
  }, [dispatchReset]);

  useEffect(
    () => () => {
      mounted.current = false;
    },
    [],
  );

  return {
    response,
    loading,
    error,
    success,
    execute,
    reset,
  };
}

export default usePush;
