import { useCallback, useState } from 'react';

function useLoadingState({ loading = false, success = false, error = false, response } = {}) {
  const initialState = { loading, success, error, response };
  const [state, setState] = useState(initialState);

  return {
    loading: state.loading,
    success: state.success,
    error: state.error,
    response: state.response,
    dispatchLoad: useCallback(
      () =>
        setState(prev => ({
          loading: true,
          success: false,
          error: null,
          response: prev.response,
        })),
      [],
    ),
    dispatchError: useCallback(
      payload =>
        setState(prev => ({
          loading: false,
          success: false,
          error: payload || true,
          response: prev.response,
        })),
      [],
    ),
    dispatchSuccess: useCallback(
      payload =>
        setState({
          loading: false,
          success: true,
          error: null,
          response: payload || null,
        }),
      [],
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    dispatchReset: useCallback(() => setState(initialState), []),
  };
}

export default useLoadingState;
