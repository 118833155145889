import React, { useContext, useEffect } from 'react';
import { Heading, Text, Stack, Button, Card, Box } from 'chaser-components';
import pluralize from 'pluralize';
import { toCurrency } from '../../../../../../../util/index';
import Check from './check.svg';
import { PaymentContext } from '../../../../../../../providers/paymentContext';
import useContact from '../../../../../../../hooks/useContact';
import useCreditor from '../../../../../../../hooks/useCreditor';
import { useHistory } from 'react-router-dom';
import ApiService from '../../../../../../../services/api';
import { useQuery } from 'react-query';
import markInvoicesAsPendingPayment from '../../../../../../../services/markInvoicesAsPendingPayment';
import { unipaasAuthorizationStatus, paymentProviders } from '../../../../../../../util/constants';

const verifyUnipaasPayment = async (_, orgId, authorizationId) => {
  const { data } = await ApiService().get(
    `/verifyPayment/${orgId}/${authorizationId}?provider=unipaas`,
  );
  return data;
};

const SuccessMessage = () => {
  const {
    setShowModal,
    paymentOptions: { paymentResult },
  } = useContext(PaymentContext);
  const history = useHistory();
  const { orgId } = useContact();
  const { name } = useCreditor();

  const { data: payment } = useQuery(
    ['payment', orgId, paymentResult?.authorizationId],
    verifyUnipaasPayment,
    {
      enabled: paymentResult?.authorizationId,
    },
  );

  useEffect(() => {
    if (payment?.authorizationStatus === unipaasAuthorizationStatus.captured) {
      markInvoicesAsPendingPayment(
        payment.transactions[0].metadata.paymentId,
        paymentProviders.unipaas,
        paymentResult?.authorizationId,
      );
    }
  }, [payment.authorizationStatus, payment.transactions, payment.paymentOption, paymentResult]);

  function onDone() {
    history.push({ search: '' });
    setShowModal(false, { reset: false });
    window.location.reload(false);
  }

  return (
    <Stack gap="medium">
      <Card width={'500px'}>
        <Box p="medium">
          <Stack gap="medium" textAlign="center">
            <img src={Check} alt="" width={'50px'} style={{ alignSelf: 'center' }} />
            <>
              <Heading>Payment successful</Heading>
              <Stack gap="small">
                <Text color="Mid Neutral 1">To {name}</Text>
                <Text color="Mid Neutral 1">
                  {pluralize(
                    'invoice',
                    parseInt(payment?.transactions[0]?.metadata?.totalInvoicesPaid),
                    true,
                  )}
                </Text>
                <Text color="Mid Nuetral 1">
                  {toCurrency(payment?.amount || 0, payment?.currency || 'gbp')}
                </Text>
              </Stack>
            </>
            <Stack gap="xsmall">
              <Button data-testid="done-btn" style={{ width: '100%' }} onClick={onDone}>
                Done
              </Button>
            </Stack>
          </Stack>
        </Box>
      </Card>
      <Text textAlign="center" color="Mid Neutral 2"></Text>
    </Stack>
  );
};

export default SuccessMessage;
