import React, { Suspense, createContext, useRef, useMemo, useContext } from 'react';
import { node, any, bool } from 'prop-types';
import LoadingBar from 'react-top-loading-bar';
import colors from '../../mixins/colorMap';
import ErrorBoundary from '../ErrorBoundary';
import LoadingMessage from '../LoadingMessage/index';
import style from './LoadBoundary.module.scss';

const LoadContext = createContext();

const LoadBoundary = ({ children, fallback, center, loadingBar: showLoadingBarForUpdates }) => {
  const parentCtx = useLoadContext();
  const loadingBar = useRef();
  const updatingInstances = useRef(new Set());

  let el = fallback || <LoadingMessage center={center} />;
  if (process.env.NODE_ENV === 'test') {
    el = <div data-testid="suspense-spinner">{el}</div>;
  }

  function add(promise) {
    if (!showLoadingBarForUpdates && parentCtx) {
      parentCtx.add(promise);
    }

    const instances = updatingInstances.current;

    if (!instances || !loadingBar.current) return;

    const shouldStart = instances.size === 0;
    instances.add(promise);

    if (shouldStart) {
      loadingBar.current.continousStart(20);
    }
  }

  function remove(promise) {
    if (!showLoadingBarForUpdates && parentCtx) {
      parentCtx.remove(promise);
    }

    const instances = updatingInstances.current;

    if (!instances || !loadingBar.current) return;

    instances.delete(promise);

    if (instances.size === 0) {
      loadingBar.current.complete();
    }
  }

  const ctx = useMemo(
    () => ({
      add,
      remove,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return (
    <>
      {showLoadingBarForUpdates && (
        <div className={style.loadingBarWrapper}>
          <LoadingBar
            ref={loadingBar}
            className={style.loadingBar}
            height={3}
            color={colors['Mid Neutral 3']}
          />
        </div>
      )}
      <LoadContext.Provider value={ctx}>
        <ErrorBoundary center={center}>
          <Suspense fallback={el}>{children}</Suspense>
        </ErrorBoundary>
      </LoadContext.Provider>
    </>
  );
};

LoadBoundary.propTypes = {
  children: node.isRequired,
  fallback: any,
  center: bool,
  loadingBar: bool,
};

LoadBoundary.defaultProps = {
  fallback: null,
  center: false,
  loadingBar: false,
};

function useLoadContext() {
  return useContext(LoadContext);
}

export default LoadBoundary;
export { LoadContext, useLoadContext };
