import { useQuery } from 'react-query';
import ApiService from '../services/api';
import useContact from './useContact';
import useCreditor from './useCreditor';
import useProvider from './useProvider';
import { paymentEnabled, hasDeprecatedAccount } from '../util';

const getInvoices = async (
  key,
  orgId,
  contactId,
  paymentProvider,
  baseCurrency,
  accountingSoftware,
) => {
  const { data } = await ApiService().get(`/creditor/${orgId}/debtor/${contactId}/invoices?paid=0`);

  const invoices = data.map(inv => {
    return {
      ...inv,
      cardPaymentActive:
        hasDeprecatedAccount(baseCurrency, paymentProvider, [inv]) ||
        paymentEnabled(paymentProvider, [inv], inv.CurrencyCode, accountingSoftware),
    };
  });

  return invoices;
};

function useUnpaidInvoices() {
  const { BaseCurrency, accountingSoftware } = useCreditor();
  const { orgId, contactId } = useContact();
  const paymentProvider = useProvider();

  const { data, isLoading } = useQuery(
    ['invoices', orgId, contactId, paymentProvider, BaseCurrency, accountingSoftware],
    getInvoices,
    {
      enabled: contactId,
      suspense: false,
    },
  );

  return {
    invoices: data,
    isLoading,
    provider: paymentProvider?.provider,
  };
}

export default useUnpaidInvoices;
