import useCreditor from './useCreditor';
import useSelectedTotal from './useSelectedTotal';
import pick from 'lodash/pick';
import omit from 'lodash/omit';
import invert from 'lodash/invert';

const BankKeys = {
  eurAccount: ['swift', 'iban'],
  ukAccount: ['sortCode', 'accountNumber'],
  cadAccount: ['name', 'financialInstitutionNumber', 'branchTransitNumber', 'accountNumber'],
  usaAccount: ['name', 'routingNumber', 'accountNumber'],
  nzAccount: ['accountNumber'],
  ausAccount: ['bsbNumber', 'accountNumber'],
};

const BankCurrencies = {
  eurAccount: 'EUR',
  ukAccount: 'GBP',
  cadAccount: 'CAD',
  usaAccount: 'USD',
  nzAccount: 'NZD',
  ausAccount: 'AUD',
};

const checkDetails = (properties, bankDetails) =>
  bankDetails && properties.every(p => p in bankDetails && bankDetails[p] !== '');

function hasCompleteAccount(bankDetails) {
  if (!bankDetails) return false;

  return Object.keys(bankDetails).some(key => {
    const properties = BankKeys[key];
    return checkDetails(properties, bankDetails[key]);
  });
}

function fixDeprecatedBankDetails(bankDetails) {
  const eurAccount = pick(bankDetails, BankKeys.eurAccount);
  const ukAccount = pick(bankDetails, BankKeys.ukAccount);
  const accounts = omit(bankDetails, [...BankKeys.eurAccount, ...BankKeys.ukAccount]);

  const fixedAccounts = {
    ...accounts,
  };

  if (Object.keys(eurAccount).length > 0) {
    fixedAccounts.eurAccount = eurAccount;
  }

  if (Object.keys(ukAccount).length > 0) {
    fixedAccounts.ukAccount = ukAccount;
  }

  return fixedAccounts;
}

function useBankDetails() {
  const { payments, baseCurrency } = useCreditor();
  const { numberOfInvoices, invoices } = useSelectedTotal();
  const bankDetails = payments?.bankDetails;

  if (numberOfInvoices === 0) return false;
  if (!bankDetails) return false;

  const filteredBankDetails =
    bankDetails &&
    Object.keys(bankDetails)
      .filter(key => Object.values(bankDetails[key]).some(value => value))
      .reduce((obj, key) => {
        obj[key] = bankDetails[key];
        return obj;
      }, {});

  const fixedDeprecatedAccounts = fixDeprecatedBankDetails(filteredBankDetails);
  const hasBankDetails = hasCompleteAccount(fixedDeprecatedAccounts);

  if (!hasBankDetails) return false;

  const currencyToAccount = invert(BankCurrencies);
  const uniqueAccountsKeys = [
    ...new Set([
      ...invoices.map(i => currencyToAccount[i.CurrencyCode]),
      currencyToAccount[baseCurrency],
    ]),
  ];

  const availableBankDetails = Object.fromEntries(
    uniqueAccountsKeys
      .map(key => [key, fixedDeprecatedAccounts[key]])
      .filter(([_, value]) => value !== undefined),
  );

  if (Object.keys(availableBankDetails).length === 0) {
    return false;
  }

  return availableBankDetails;
}

export default useBankDetails;
export { BankCurrencies };
