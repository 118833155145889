import { useParams } from 'react-router-dom';

function useContact() {
  const { orgId, contactId } = useParams();

  return {
    orgId,
    contactId,
  };
}

export default useContact;
