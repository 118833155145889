/* eslint-disable react/prop-types, no-return-assign, no-cond-assign */
import React from 'react';
import {
  ToastContainer as ToastifyContainer,
  toast as toastify,
  cssTransition,
} from 'react-toastify';
import { Button, Shelf, Icon, Text } from 'chaser-components';
import 'react-toastify/dist/ReactToastify.min.css';
import style from './ToastContainer.module.scss';

const Transition = cssTransition({
  enter: style.enter,
  exit: style.exit,
});

// Update transition only works if the new transition is different
// Flip-flop between transitions, otherwise it will only work for the first update
let flip = false;
const UpdateTransition = cssTransition({
  enter: style.updated,
  exit: style.exit,
});
const UpdateTransition2 = cssTransition({
  enter: style.updated,
  exit: style.exit,
});

const ToastContainer = () => {
  return (
    <ToastifyContainer
      position="top-center"
      hideProgressBar
      autoClose={false}
      closeButton={false}
      className={style.container}
      toastClassName={style.toast}
      bodyClassName={style.body}
      transition={Transition}
    />
  );
};

function getIcon(type) {
  switch (type) {
    case 'error':
      return <Icon name="alert-filled" color="Red" />;
    case 'success':
      return <Icon name="check-filled" color="Green" />;
    default:
      return null;
  }
}

const Toast = ({ type, msg, closeToast }) => {
  return (
    <Shelf gap="xsmall" flexWrap="nowrap">
      <span style={{ minWidth: 20 }}>{getIcon(type)}</span>
      <Text color="White" trim={false}>
        {msg}
      </Text>{' '}
      <Button
        onClick={closeToast}
        icon={<Icon name="close" color="white" size={12} />}
        size="narrow"
        appearance="minimal"
        aria-label="Close"
        className={style.close}
      />
    </Shelf>
  );
};

function showOrUpdate(msg, { toastId, ...opts }) {
  return !toastId || !toastify.isActive(toastId)
    ? toastify(msg, { toastId, ...opts })
    : toastify.update(toastId, {
      render: msg,
      ...opts,
      transition: (flip = !flip) === true ? UpdateTransition : UpdateTransition2,
    });
}

const toast = {
  error: (msg, opts) =>
    showOrUpdate(<Toast msg={msg} type="error" />, { autoClose: 5000, ...opts }),
  success: (msg, opts) =>
    showOrUpdate(<Toast msg={msg} type="success" />, { autoClose: 3000, ...opts }),
  dismiss: toastify.dismiss,
};

export default ToastContainer;
export { toast };
